<template>
  <div>
    <b-card-text class="mb-0 font-weight-bold">
      {{ name }}
    </b-card-text>
    <b-form-group>
      <small>{{ $t('Formulario') }}</small>
      <v-select
        label="name"
        :filterable="false"
        :searchable="false"
        :options="selectForms"
        v-model="info.config.defaultForm"
        :disabled="isDisabled"
      />
    </b-form-group>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import {
  BFormCheckbox, BCardText, BFormGroup,
} from 'bootstrap-vue'

export default {
  name: 'Form',
  components: {
    vSelect,
    BFormGroup,
    BCardText,
    BFormCheckbox,
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      defaultForm: 0,
      readonly: 0,
    }
  },
  computed: {
    ...mapGetters({
      selectForms: 'forms/getItems',
    }),
    name() {
      return this.info.name
    },
    isDisabled() {
      if (this.info.config.readonly) {
        return true
      }
      return false
    },
  },
}

</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
