<template>
  <b-col cols="6">
    <div class="mb-1 d-flex align-items-center justify-content-between">
      <b-dropdown :text="textType" variant="primary">
        <b-dropdown-item @click="setType('assets')" v-if="typesAvailable['assets']" >
          {{ $t("Equipos") }}
        </b-dropdown-item>
        <b-dropdown-item @click="setType('epis')" v-if="typesAvailable['epis']" >
          {{ $t("EPIs") }}
        </b-dropdown-item>
        <b-dropdown-item @click="setType('installations')" v-if="typesAvailable['installations']" >
          {{ $t("Instalaciones") }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="mb-1 align-items-center justify-content-between">
      <b-row>
        <b-col>
          <b-form-group :label="$t('Busqueda')" label-for="filter-search">
            <b-row>
              <b-col sm="8">
                <b-form-input
                  v-model="search"
                  :placeholder="$t('Busqueda')"
                  type="text"
                  class="d-inline-block"
                />
              </b-col>
              <b-col sm="4" style="padding-left: 0px">
                <b-button @click="handleSearch" variant="primary">
                  {{ $t("Buscar") }}
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-table-simple hover small caption-top responsive>
      <b-thead>
        <b-tr>
          <b-th>
            <span v-if="type === 'assets'">{{ $t("Equipos") }}</span>
            <span v-if="type === 'epis'">{{ $t("EPIs") }}</span>
            <span v-if="type === 'installations'">{{ $t("Instalaciones") }}</span>
          </b-th>
        </b-tr>
      </b-thead>
      <draggable
        :list="elements"
        :group="{ name: 'products', pull: 'clone', put: false }"
        tag="tbody"
      >
        <b-tr :key="index" v-for="(item, index) in elements">
          <b-td class="d-flex align-items-center justify-content-between py-2">
            <div>
              <div
                  v-if="item.product"
                :style="`background-image: url('${item.product.docs.imagen}');`"
                class="d-inline-block img-product-list mr-50"
              ></div>
              <p class="d-inline-block m-0 font-weight-bolder">
                <span class="d-block">{{ item.name }}</span>

                <b-badge
                  v-if="item.status"
                  :variant="statusAssets[item.status.alias]"
                  class="mr-50"
                >
                  {{ $t(`status.assets.${item.status.alias}`) }}
                </b-badge>
              </p>
            </div>
          </b-td>
          <draggable
            :list="item.objects"
            :group="{ name: 'products', pull: 'clone', put: false }"
            tag="tbody"
            v-if="type === 'sets' && openPack == index"
          >
            <b-tr :key="index1" v-for="(item1, index1) in item.objects">
              <b-td class="d-flex align-items-center px-3">
                <div
                  :style="`background-image: url('${item1.imagen}');`"
                  class="d-inline-block img-product-list mr-50"
                ></div>
                <div>
                  <p class="m-0">{{ item1.name }}</p>
                  <small v-if="item1.type === 'asset'">{{
                    $t("Activo")
                  }}</small>
                </div>
              </b-td>
            </b-tr>
          </draggable>
        </b-tr>
      </draggable>
    </b-table-simple>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
        <b-form-select
          v-model="pageLength"
          :options="pages"
          class="ml-50 mr-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> {{ $t("Total") }}: {{ totalElements }}</span>
      </div>
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalElements"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </b-col>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import { config } from "@/shared/app.config";
import {
  BCol,
  BRow,
  BTh,
  BTr,
  BTd,
  BTableSimple,
  BThead,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BBadge,
  BButton,
  BForm,
  BFormGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  name: 'DraggableListAssets',
  components: {
    BCol,
    BRow,
    BTh,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    draggable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    BButton,
    BForm,
    BFormGroup,
    BInputGroupAppend,
  },
  props: {
    event: {
      required: false
    }
  },
  data() {
    return {
      type: "assets",
      openPack: 9999,
      searchTerm: "",
      search: "",
      currentPage: 1,
      pageLength: 10,
      pages: ["10", "15", "25"],
      statusAssets: config.statusAssetsVariants,
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "companies/getCurrentCompany",
      currentClient: "clients/getCurrentClient",
      epis: "epis/getItems",
      assets: "assets/getItems",
      installations: "installations/getItems",
      totalInstallations: "installations/getTotalItems",
      totalAssets: "assets/getTotalItems",
      totalEpis: "epis/getTotalItems",
    }),
    typesAvailable() {
      const types = JSON.parse(localStorage.getItem('typesAvailables'))
      return types
    },
    elements() {
      let output = [];
      if (this.type === "epis") {
        this.epis.forEach((element) => {
          element.type = "epi";
          output.push(element);
        });
      } else if (this.type === "assets") {
        this.assets.forEach((element) => {
          element.type = "asset";
          output.push(element);
        });
      }  else if (this.type === "installations") {
        this.installations.forEach((element) => {
          element.type = "installation";
          output.push(element);
        });
      }
      return output;
    },
    totalElements() {
      if (this.type === "installations") {
        return this.totalInstallations;
      } else if (this.type === "assets") {
        return this.totalAssets;
      } else if (this.type === "epis") {
        return this.totalEpis;
      }
      return 0;
    },
    textType() {
      if (this.type === "installations") {
        return this.$t("Instalaciones");
      } else if (this.type === "assets") {
        return this.$t("Equipos");
      } else if (this.type === "epis") {
        return this.$t("EPIs");
      }
      return "";
    },
  },
  watch: {
    elements() {
      this.openPack = 999;
    },
    type() {
      this.chargeElements();
    },
  },
  methods: {
    ...mapActions({
      getInstallations: "installations/getListInstallations",
      getAssets: "assets/getListAssets",
      getEpis: "epis/getListEpis",
    }),
    chargeElements() {
      let group = "";
      this.openPack = 999;
      if (this.group) {
        group = this.group.id;
      }
      if (this.type === "installations") {
        this.getInstallations({
          client: this.currentClient.id,
          page: this.currentPage,
          per_page: this.pageLength,
          search: this.searchTerm,
        });
      } else if (this.type === "assets") {
        this.getAssets({
          client: this.currentClient.id,
          page: this.currentPage,
          per_page: this.pageLength,
          search: this.searchTerm,
          group,
          reload: true
        });
      } else if (this.type === "epis") {
        this.getEpis({
          client: this.currentClient.id,
          page: this.currentPage,
          per_page: this.pageLength,
          search: this.searchTerm,
        });
      }
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeElements();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeElements();
    },
    handleSearch() {
      this.searchTerm = this.search;
      this.currentPage = 1;
      this.chargeElements();
    },
    setType(type) {
      this.type = type;
    },
  },
  async created() {
    if (this.typesAvailable['assets']) {
      await this.chargeElements()
    } else if (this.typesAvailable['epis']) {
      await this.setType('epis')
    } else if (this.typesAvailable['installations']) {
      await this.setType('installations')
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
